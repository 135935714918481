import i18n from "@/plugins/i18n";

export const _GENDER = {
  1: i18n.t("select.gender.male"),
  2: i18n.t("select.gender.female"),
  3: i18n.t("select.gender.others"),
};
export const GENDERS = [
  { value: 1, label: i18n.t("select.gender.male") },
  { value: 2, label: i18n.t("select.gender.female") },
  { value: 3, label: i18n.t("select.gender.others") },
];
