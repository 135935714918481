/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg-8>
    <v-flex xs12>
      <v-layout wrap justify-start>
        <v-flex xs12 md3 lg3 xl3 pr-2 pr-lg-4>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line blue"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">{{ pagination.total }}</span> <br />
                <span class="subtitle-1">
                  {{ $t("pages.employees.total_employees") }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-spacer />
        <v-flex xs12 md9 lg7 xl5 pt-2 pt-lg-0 align-self-center>
          <v-layout wrap justify-start>
            <v-flex xs6 md3 pr-2 text-right align-self-center>
              <v-select
                :label="$t('form.gender')"
                :items="GENDERS"
                item-value="value"
                item-text="label"
                outlined
                dense
                hide-details
                v-model="filter.gender"
                clearable
                @change="prepareFetchData(false)"
              ></v-select>
            </v-flex>
            <v-flex xs6 md3 pr-2 text-right align-self-center>
              <v-select
                :label="$t('form.qualification')"
                outlined
                dense
                hide-details
                :items="qualifications"
                v-model="filter.qualifications"
                item-text="name"
                item-value="id"
                clearable
                @change="prepareFetchData(false)"
              ></v-select>
            </v-flex>
            <v-flex xs6 md3 pr-2 text-right align-self-center>
              <v-text-field
                :placeholder="$t('form.search')"
                type="search"
                outlined
                dense
                hide-details
                append-icon="mdi-magnify"
                v-model="filter.search"
                @change="prepareFetchData(false)"
              />
            </v-flex>
            <v-flex xs6 md3 align-self-center text-right>
              <AppNavButton
                :route="{ name: 'employees.add' }"
                custom-class="pa-5"
                block
              >
                <template #content>
                  <v-icon size="14px">mdi-plus</v-icon>
                  {{ $t("pages.employees.add_employee") }}
                </template>
              </AppNavButton>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start py-4 pt-8>
        <v-flex xs12 sm6 lg2 align-self-center>
          <h3>
            {{ $t("pages.employees.all_employees") }}
          </h3>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start>
        <v-flex xs12>
          <AppSimpleTable
            :headings="headings"
            :items="list"
            :pagination="pagination"
            empty-title="pages.employees.no_employees_to_show"
            @paginate="
              (val) => ((pagination.page = val), prepareFetchData(true))
            "
            @delete="toggleDelete"
          >
            <template v-slot:inlineAction="{ data }">
              <v-flex text-center>
                <img
                  v-if="data.item.userStatus"
                  src="@/assets/icons/trash.svg"
                  height="20px"
                  alt="delete"
                  class="cursor-pointer"
                  @click="toggleDelete(data.item)"
                />
                <v-icon
                  v-else
                  class="cursor-pointer"
                  @click="toggleRestore(data.item)"
                >
                  mdi-reload
                </v-icon>
              </v-flex>
            </template>
          </AppSimpleTable>
        </v-flex>
      </v-layout>
    </v-flex>
    <DeleteConfirmation
      v-model="showDelete"
      :item="selectedItem"
      @cancel="toggleDelete"
      @submit="confirmDeleteEmployee"
    />
    <ActionConfirmation
      v-model="showRestore"
      :item="selectedItem"
      @cancel="toggleRestore"
      @submit="confirmRestoreEmployee"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GENDERS } from "@/constants/genders";
import Helpers from "@/libs/helper/components/helper-functions";

export default {
  name: "Employees",
  data: () => ({
    selectedItem: {},
    showDelete: false,
    showRestore: false,
    GENDERS,
    page: 1,
    filter: {
      gender: null,
      qualifications: null,
      search: null,
      sort_by: ["created_at"],
      sort_by_dir: ["desc"],
    },
    headings: [
      { name: "", value: "index", style: "width:80px" },
      { name: "form.name", value: "name" },
      { name: "pages.employees.joined_date", value: "date" },
      { name: "pages.employees.total_assignments", value: "assignments" },
      { name: "table.status", value: "status" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: ["view", "edit"],
        view: "employee",
        edit: "employee.edit",
      },
    ],

    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      employees: "hospital/getEmployees",
      qualifications: "app/getQualifications",
    }),
    list() {
      return this.employees.map((itm, index) => {
        return {
          index: this.pagination.from + index,
          id: itm.id,
          name: itm.first_name + " " + itm.last_name,
          date: this.$moment(itm.created_at).format("DD MMM YYYY"),
          assignments: itm.total_assignments,
          userStatus: itm.user.is_active,
          status: itm.user.is_active
            ? this.$t("filters.active")
            : this.$t("filters.inactive"),
        };
      });
    },
  },
  created() {
    this.filter.gender = parseInt(this.$route.query.gender) || null;
    this.filter.qualifications =
      parseInt(this.$route.query.qualifications) || null;
    this.filter.search = this.$route.query.search || null;
    this.pagination.page = parseInt(this.$route.query.page) || 1;
    this.getData();
  },
  mounted() {
    const breadCrumbs = [
      {
        name: this.$route.name,
        text: "pages.employees.title",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
  methods: {
    ...mapActions({
      getEmployeeList: "hospital/getEmployeeList",
      deleteEmployee: "hospital/deleteEmployee",
      restoreEmployee: "hospital/restoreEmployee",
    }),

    updateQueryParams() {
      this.$router.replace({
        query: {
          gender: this.filter.gender,
          qualifications: this.filter.qualifications,
          search: this.filter.search,
          page: this.pagination.page,
        },
      });
    },
    prepareFetchData(paginate = false) {
      if (!paginate) this.pagination.page = 1;
      this.updateQueryParams();
      this.getData();
    },
    getData() {
      const payload = {
        ...this.pagination,
        ...this.filter,
      };
      this.getEmployeeList(payload).then((res) => {
        this.pagination = Helpers.setPagination(res);
      });
    },
    toggleDelete(val) {
      if (val) this.selectedItem = val;
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    toggleRestore(val) {
      if (val) this.selectedItem = val;
      else this.selectedItem = {};
      this.showRestore = !this.showRestore;
    },
    confirmDeleteEmployee() {
      this.deleteEmployee(this.selectedItem.id).then(() => {
        this.toggleDelete();
        this.getData();
      });
    },
    confirmRestoreEmployee() {
      this.restoreEmployee(this.selectedItem.id).then(() => {
        this.toggleRestore();
        this.getData();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}
.v-text-field--outlined::v-deep input::placeholder,
.v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}
</style>
