var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    md3: "",
                    lg3: "",
                    xl3: "",
                    "pr-2": "",
                    "pr-lg-4": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line blue" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v(_vm._s(_vm.pagination.total)),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("span", { staticClass: "subtitle-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.employees.total_employees")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    md9: "",
                    lg7: "",
                    xl5: "",
                    "pt-2": "",
                    "pt-lg-0": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "pr-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("form.gender"),
                              items: _vm.GENDERS,
                              "item-value": "value",
                              "item-text": "label",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.prepareFetchData(false)
                              },
                            },
                            model: {
                              value: _vm.filter.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "gender", $$v)
                              },
                              expression: "filter.gender",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "pr-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("form.qualification"),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              items: _vm.qualifications,
                              "item-text": "name",
                              "item-value": "id",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.prepareFetchData(false)
                              },
                            },
                            model: {
                              value: _vm.filter.qualifications,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "qualifications", $$v)
                              },
                              expression: "filter.qualifications",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "pr-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t("form.search"),
                              type: "search",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "append-icon": "mdi-magnify",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.prepareFetchData(false)
                              },
                            },
                            model: {
                              value: _vm.filter.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "search", $$v)
                              },
                              expression: "filter.search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "align-self-center": "",
                            "text-right": "",
                          },
                        },
                        [
                          _c("AppNavButton", {
                            attrs: {
                              route: { name: "employees.add" },
                              "custom-class": "pa-5",
                              block: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c("v-icon", { attrs: { size: "14px" } }, [
                                      _vm._v("mdi-plus"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("pages.employees.add_employee")
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            {
              attrs: { wrap: "", "justify-start": "", "py-4": "", "pt-8": "" },
            },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm6: "",
                    lg2: "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("h3", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("pages.employees.all_employees")) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("AppSimpleTable", {
                    attrs: {
                      headings: _vm.headings,
                      items: _vm.list,
                      pagination: _vm.pagination,
                      "empty-title": "pages.employees.no_employees_to_show",
                    },
                    on: {
                      paginate: (val) => (
                        (_vm.pagination.page = val), _vm.prepareFetchData(true)
                      ),
                      delete: _vm.toggleDelete,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "inlineAction",
                        fn: function ({ data }) {
                          return [
                            _c(
                              "v-flex",
                              { attrs: { "text-center": "" } },
                              [
                                data.item.userStatus
                                  ? _c("img", {
                                      staticClass: "cursor-pointer",
                                      attrs: {
                                        src: require("@/assets/icons/trash.svg"),
                                        height: "20px",
                                        alt: "delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleDelete(data.item)
                                        },
                                      },
                                    })
                                  : _c(
                                      "v-icon",
                                      {
                                        staticClass: "cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleRestore(data.item)
                                          },
                                        },
                                      },
                                      [_vm._v(" mdi-reload ")]
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        attrs: { item: _vm.selectedItem },
        on: { cancel: _vm.toggleDelete, submit: _vm.confirmDeleteEmployee },
        model: {
          value: _vm.showDelete,
          callback: function ($$v) {
            _vm.showDelete = $$v
          },
          expression: "showDelete",
        },
      }),
      _c("ActionConfirmation", {
        attrs: { item: _vm.selectedItem },
        on: { cancel: _vm.toggleRestore, submit: _vm.confirmRestoreEmployee },
        model: {
          value: _vm.showRestore,
          callback: function ($$v) {
            _vm.showRestore = $$v
          },
          expression: "showRestore",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }